/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e8f178af-4f54-4166-8f57-177fc3ee71ab",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_NZvqmer64",
    "aws_user_pools_web_client_id": "7v4oq9pacl9s6e4laguap55qm8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "oshiAPI",
            "endpoint": "https://8sg4jnrc7h.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "daemon",
            "endpoint": "https://g68rotxulh.execute-api.us-east-1.amazonaws.com",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://b6ua2bzjgvbsffqnw2cme6qlwe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dur7t5weqbdbbgryx2xawhwvwe",
    "aws_mobile_analytics_app_id": "eda4eeab6bbf44c0b7b45b494e2ddc08",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "multimedia142006-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "cacheddata-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "authsessions-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "multimediameta-dev",
            "region": "us-east-1"
        }
    ]
};
export default awsmobile;
